import { createContext, useContext, useEffect, useState } from 'react';

export const GlobalContext = createContext<{ appHeight: number }>({
  appHeight: window.innerHeight,
});

export const GlobalProvider: React.FC = (props) => {
  const [appHeight, setAppHeight] = useState(window.innerHeight);

  useEffect(() => {
    function getWindowHeight() {
      const currHeight = window.innerHeight;

      setAppHeight(currHeight);

      document.documentElement.style.setProperty(
        '--app-height',
        `${currHeight}px`,
      );
    }

    window.addEventListener('resize', getWindowHeight);

    getWindowHeight();

    return () => {};
  }, []);
  return (
    <GlobalContext.Provider value={{ appHeight }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

const useGlobal = () => useContext(GlobalContext);

export default useGlobal;
