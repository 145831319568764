import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { NotificationWrapper } from './styles';

const Notification = forwardRef<
  NotificationMethods,
  React.HTMLProps<HTMLDivElement>
>((_props, ref) => {
  const [notificationOptions, setNotificationOptions] =
    useState<null | NotificationShowProp>(null);

  const intervalRef = useRef<any>();
  const autoHideInterval = 10000;

  function clearCurrInterval() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }

  function hide() {
    clearCurrInterval();

    setNotificationOptions(null);
  }

  function show(options: NotificationShowProp) {
    clearCurrInterval();

    setNotificationOptions(options);

    intervalRef.current = setInterval(hide, autoHideInterval);
  }

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  return (
    <NotificationWrapper
      className={`notification ${notificationOptions ? 'show' : ''} ${
        notificationOptions?.type || ''
      }`}
      onClick={(e) => {
        e.stopPropagation();
        hide();
      }}
    >
      <div className="notification-body">
        {notificationOptions?.text || ''}
        {!!notificationOptions?.text && (
          <div className="notification-icon">
            {notificationOptions?.type === 'error' ? '😥' : '🎉'}
          </div>
        )}
      </div>
    </NotificationWrapper>
  );
});

export default Notification;
