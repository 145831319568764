import React, { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import useGlobal, { GlobalProvider } from './hooks/useGlobal';
import { NotificationProvider } from './hooks/useNotification';
import './index.css';
import AppRoutes from './routes';
import ThemeProvider from './theme';

const DesktopBlock = lazy(
  () => import(/* webpackChunkName: "desktop" */ './screens/DesktopBlock'),
);

const MainWrapper = () => {
  const { appHeight } = useGlobal();

  return (
    <div className="app-wrapper" style={{ height: appHeight }}>
      <ThemeProvider>
        <AppRoutes />
      </ThemeProvider>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <BrowserRouter basename="/">
      <GlobalProvider>
        <NotificationProvider>
          <MainWrapper />

          <Suspense
            fallback={
              <div
                className="route"
                style={{
                  height: 'var(--app-height)',
                  backgroundColor: '#67818d',
                }}
              ></div>
            }
          >
            <DesktopBlock />
          </Suspense>
        </NotificationProvider>
      </GlobalProvider>
    </BrowserRouter>
  );
};

export default App;
