import styled from 'styled-components';

export const NotificationWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  cursor: pointer;

  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  overflow: hidden;

  width: 100%;
  z-index: 9;
  margin: 0 auto;
  background-color: transparent;
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  font-size: 0.875rem;

  .notification-body {
    padding: 1.5rem 1rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .notification-icon {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 2.75rem;
    font-size: 1.5rem;
    width: 2.75rem;
    border-radius: 9999px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  &.success {
    background-color: #3aba83;

    .notification-icon {
      box-shadow: 0 0 10px 0 #30a070;
    }
  }

  &.error {
    background-color: #fe7575;

    .notification-icon {
      box-shadow: 0 0 10px 0 #d86460;
    }
  }

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
`;
